
import * as __star__ from '@clayui/popover';

const {
default: __default__,
ALIGN_POSITIONS,
__esModule
} = __star__;

export {
ALIGN_POSITIONS,
__esModule
};
export default __default__;
